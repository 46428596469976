import React, { useEffect, useState } from 'react';

import ReactGA from 'react-ga4';
import { jwtDecode } from 'jwt-decode';
import logo from '../../assets/img/logo.svg';
import style from './style.module.scss';
import { useNavigate } from 'react-router-dom';

const EmptyPage = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/empty", title: "Empty" });
    const token = localStorage.getItem('token');
    if (token === '' || token === null || token.split('.').length !== 3) {
      localStorage.removeItem('token');
      return navigate('/request');
    } else {
      try {
        const decode = jwtDecode(token);
        setEmail(() => decode.email);
      } catch (error) {
        localStorage.removeItem('token');
        navigate('/request');
      }
    }
  }, [navigate]);

  return (
    <div className={style['empty-page']}>
      <div className={style.wrapper}>
        <div className={style.request}>
          <h1>
            ¡Saludos!
          </h1>
          <div className={style.text}>
            No hay ningún record de paciente asociado con la dirección de correo electrónico a continuación.
            <br /><br />
            <strong style={{ fontSize: '1.5rem', lineHeight: '2rem', wordWrap: 'break-word' }}>{email}</strong>
            <br /><br />
            Favor llame al laboratorio participante para asistencia.
          </div>
          <br />
        </div>
      </div>
      <div className={style.footer}>
        <div className={style.copyright}>&#169; Copyright 2011-present. All Rights Reserved.</div>
        <img src={logo} alt="" />
        <div>Powered by Salud Medica Inc.</div>
      </div>
    </div>
  );
}
export default EmptyPage;
