import React, { useEffect, useState } from 'react';

import ConfirmPart from '../../components/ConfirmPart';
import ExpiredPart from '../../components/ExpiredPart';
import ReactGA from 'react-ga4';
import { jwtDecode } from 'jwt-decode';
import logo from '../../assets/img/logo.svg';
import style from './style.module.scss';
import { useNavigate } from 'react-router-dom';

const ExpiredPage = () => {
  const navigate = useNavigate();
  const [isRequested, setIsRequested] = useState(false);
  const [email, setEmail] = useState('');

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/expired", title: "Expired" });
    const token = localStorage.getItem('token');
    if (token === '' || token === null) {
      localStorage.removeItem('token');
      return navigate('/request');
    } else if (token.split('.').length !== 3) {
      localStorage.removeItem('token');
      return navigate('/request');
    } else {
      try {
        const decode = jwtDecode(token);
        setEmail(() => decode.email);
        localStorage.setItem('name', `${decode.given_name}`);
      } catch (error) {
        localStorage.removeItem('token');
        navigate('/request');
      }
    }
  }, [navigate]);

  const ExpiredPartComponent = () => {
    return (
      <div className={style['expired-page']}>
        <div className={style.wrapper}>
          {isRequested ? (
            <ConfirmPart setIsRequested={setIsRequested} email={email} />
          ) : (
            <ExpiredPart setIsRequested={setIsRequested} email={email} />
          )}
        </div>
        <div className={style.footer}>
          <div className={style.copyright}>&#169; Copyright 2011-present. All Rights Reserved.</div>
          <img src={logo} alt="" />
          <div>Powered by Salud Medica Inc.</div>
        </div>
      </div>
    );
  };

  const getProperActions = () => {
    const token = localStorage.getItem('token');
    if (token === '' || token === null) {
      return false;
    } else {
      if (token.split('.').length !== 3) {
        localStorage.removeItem('token');
        return false;
      }
    }

    return true;
  };

  return <>{getProperActions() ? <ExpiredPartComponent /> : ''}</>;
};

export default ExpiredPage;
