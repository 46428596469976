import React, { createContext, useContext, useState } from 'react';

const CardsContext = createContext(undefined);

export const useCards = () => {
  return useContext(CardsContext);
};

export const GetInfoCardsProvider = ({ children }) => {
  const [cards, setCards] = useState(null);
  const [pdfMob, setPdfMob] = useState(false);
  const [pdfVisible, setPdfVisible] = useState(false);
  const [drawerPdfDesktop, setDrawerPdfDesktop] = useState(false);
  const [blur, setBlur] = useState(false);
  const [config, setConfig] = useState(null);
  const [configFiltered, setConfigFiltered] = useState(null);
  const [abnormalChecked, setAbnormalChecked] = useState(false);
  const [namesChecked, setNamesChecked] = useState([]);
  const [labsChecked, setLabsChecked] = useState([]);
  const [plug, setPlug] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [scrollFilter, setScrollFilter] = useState(false);
  const [pdfDownload, setPdfDownload] = useState(null);

  const filterCards = (value) => {
    setScrollFilter((prev) => !prev);
    setPlug(() => false);
    if (!value.length) {
      return setConfigFiltered(() => null);
    }

    let filtered = [];
    const filterAbnormal = value.some((item) => item.charAt(0) === 'A');
    const filterNames = value.filter((item) => item.charAt(0) === 'N').map(item => item.substring(2));
    const filterLabs = value.filter((item) => item.charAt(0) === 'L').map(item => item.substring(2));

    for (let configElement of config){
      let filterApplied = false;
      if (filterNames.length){
        for (let filterName of filterNames) {
          if (configElement.first_name_age === filterName) {
            filtered.push(configElement);
            filterApplied = true;
          }
        }
      }

      if (filterApplied) continue;

      if (filterLabs.length){
        for (let filterLab of filterLabs) {
          if (configElement.clinica === filterLab) {
            filtered.push(configElement);
          }
        }
      }
    }

    if (!filtered.length){
      filtered = [...config];
    }

    if (filterAbnormal) {
      filtered = filtered.filter((item) => item.flag)
    }

    if (!filtered.length) setPlug(() => true);
    setConfigFiltered(() => filtered);
  };

  return (
    <CardsContext.Provider
      value={{
        cards,
        setCards,
        pdfMob,
        setPdfMob,
        pdfVisible,
        setPdfVisible,
        setDrawerPdfDesktop,
        drawerPdfDesktop,
        blur,
        setBlur,
        config,
        setConfig,
        filterCards,
        abnormalChecked,
        setAbnormalChecked,
        namesChecked,
        setNamesChecked,
        labsChecked,
        setLabsChecked,
        configFiltered,
        setConfigFiltered,
        plug,
        draweropen: drawerOpen,
        setDrawerOpen,
        scrollFilter,
        pdfDownload,
        setPdfDownload,
      }}>
      {children}
    </CardsContext.Provider>
  );
};
