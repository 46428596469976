import {
  BackSVG,
  EmailSVG,
  HouseSVG,
  IconDownload,
  ImageDownlaodSVG,
  ImageDownlaodSVGBlack,
  PrintSVG,
  SrcollDown,
  SrcollUp,
} from '../icons';
import React, { useEffect, useRef, useState } from 'react';

import Button from '@material-ui/core/Button';
import ComponentToPrint from '../../components/ComponentToPrint';
import PdfComponent from '../../components/PdfComponent';
import { getPDF } from '../../core/services/cards';
import style from './style.module.scss';
import useAnalyticsEventTracker from '../../core/hooks/analyticsEventTracker';
import { useCards } from '../cardsContext';
import { useNavigate } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';

const DrawerCard = ({ config, analPerson, setDrawerOpen, draweropen }) => {
  const [listNews, setListNews] = useState(null);
  const heightUl = useRef();
  const heightWrapperUl = useRef();
  const { setPdfMob, pdfVisible, setPdfVisible, setDrawerPdfDesktop, setBlur } = useCards();
  const [card, setCard] = useState(null);
  const refPrint = useRef();
  const [PDF, setPDF] = useState([]);
  const navigate = useNavigate();
  const [scrollTop, setScrollTop] = useState(true);
  const [scrollBottom, setScrollBottom] = useState(false);
  const [hideButtonsScroll, setHideButtonsScroll] = useState(null);

  const hasPdf = config?.find(item => item.id === analPerson)?.has_pdf ?? false;

  const gaEventTracker = useAnalyticsEventTracker('Results');

  useEffect(() => {
    if (!analPerson || !hasPdf) {
      return;
    }

    getPDF(analPerson, navigate).then((data) => {
      const listPages = [];
      for (let item of data) {
        listPages.push(item.Media);
      }
      setPDF(() => listPages);
    });
  }, [config, hasPdf, analPerson, navigate]);

  useEffect(() => {
    if (analPerson !== null) {
      const res = config.filter((item) => item.id === analPerson);
      const [first] = res;
      setCard(() => first);
      setListNews(() => res[0].news);
    }

    setTimeout(() => {
      if (heightUl?.current?.clientHeight + 88 >= heightWrapperUl?.current?.clientHeight) {
        setHideButtonsScroll(() => true);
      } else {
        setHideButtonsScroll(() => false);
      }
    }, 1);

    return () => {
      setListNews(() => null);
      setHideButtonsScroll(() => null);
      setHideButtonsScroll(() => null);
    };
  }, [draweropen, analPerson, config]);

  const checkForColor = (flag) => {
    if (!flag) return 'white';
    if (flag) return '#FFDFAF';
  };

  const getPDFHandlerMob = () => {
    gaEventTracker('View', 'PDF document');
    setPdfMob(() => true);
    setPdfVisible(() => true);
    // setLoading(() => true);
    // getPDF(analPerson, history).then((data) => {
    //   const listPages = [];
    //   for (let item of data) {
    //     listPages.push(item.Media);
    //   }
    //   setPdfMob(() => true);
    //   setPDF(() => listPages);
    //   setPdfVisible(() => true);
    //   setLoading(() => false);
    // });
  };
  const getPDFHandlerDesc = () => {
    gaEventTracker('View', 'PDF document');
    setPdfVisible(() => true);
    setDrawerPdfDesktop(() => true);
    // getPDF(analPerson, history).then((data) => {
    //   const listPages = [];
    //   for (let item of data) {
    //     listPages.push(item.Media);
    //   }
    //   setPDF(() => listPages);
    //   setPdfVisible(() => true);
    //   setDrawerPdfDesktop(() => true);
    // });
  };

  const handlePrint = useReactToPrint({
    content: () => refPrint.current,
  });

  const scroll = (e) => {
    if (e.target.scrollTop !== 0) setScrollTop(() => false);
    if (e.target.scrollTop === 0) setScrollTop(() => true);
    if (e.target.scrollTop === heightUl.current.clientHeight - heightWrapperUl.current.clientHeight + 88)
      setScrollBottom(() => true);

    if (e.target.scrollTop !== heightUl.current.clientHeight - heightWrapperUl.current.clientHeight + 88)
      setScrollBottom(() => false);
  };

  const scrollHandlerTop = () => {
    if (heightWrapperUl.current.scrollTop - heightWrapperUl.current.clientHeight <= 0) {
      setScrollTop(() => true);
      heightWrapperUl.current.scrollTop = 0;
    } else {
      heightWrapperUl.current.scrollTop -= heightWrapperUl.current.clientHeight;
    }
  };

  const scrollHandlerBottom = () => {
    if (heightWrapperUl.current.scrollTop + heightWrapperUl.current.clientHeight >= heightWrapperUl.current.scrollTop) {
      setScrollBottom(() => true);
      heightWrapperUl.current.scrollTop = heightWrapperUl.current.scrollTop += heightWrapperUl.current.clientHeight;
    } else {
      heightWrapperUl.current.scrollTop += heightWrapperUl.current.clientHeight;
    }
  };

  const getLabNewsMin = (labNews) => {
    if (!labNews.flagValue) {
      return <span>{`${labNews.min ?? ''}`}</span>;
    }

    switch (labNews.flagValue) {
      case 'L':
        return <span>{`${labNews.min ?? ''}`}  &#8595;</span>;
      case 'LL':
        return <span>{`${labNews.min ?? ''}`}  &#8650;</span>;
      case 'H':
        return <span>{`${labNews.min ?? ''}`}  &#8593;</span>;
      case 'HH':
        return <span>{`${labNews.min ?? ''}`}  &#8648;</span>;
      default:
        return <span>{`${labNews.min ?? ''}`} &#10003;</span>;
    }
  }

  return (
    <div className={style.wrapper}>
      {pdfVisible ? (
        <div className={style.pdf}>
          <PdfComponent PDF={PDF} title={card?.title} />
        </div>
      ) : (
        <>
          <div className={style.title_block}>
            <span
              className={style.block_back}
              onClick={() => {
                setBlur(() => false);
                setDrawerOpen(() => false);
              }}>
              <div>
                <BackSVG />
              </div>
              <h4>REGRESAR</h4>
            </span>
            <h3 className={style.panel}>{card?.title}</h3>
            <p>{card?.full_name}</p>
            <div className={style.actions}>
              {/* <input
            accept="image/*"
            className={style.input}
            style={{ display: 'none' }}
            id="raised-button-file"
            multiple
            type="file"
          />
          <label htmlFor="raised-button-file">
            <Button component="span">Upload</Button>
          </label> */}
              <div onClick={() => getPDFHandlerDesc()} className={`${!PDF.length && style.disabled}`}>
                <div>
                  <ImageDownlaodSVGBlack />
                </div>
                <h3>{!hasPdf ? 'No Hay Imagen Disponible' : !PDF.length ? 'Cargando Imagen...' : 'Ver Imagen'}</h3>
              </div>
              {hasPdf && PDF.length > 0 && (
                <>
                  <div onClick={() => handlePrint()} className={`${!PDF.length && style.disabled}`}>
                    <div>
                      <PrintSVG />
                    </div>
                    <h3>Imprimir</h3>
                  </div>
                  <a
                    className={`${style.download} ${!PDF.length && style.disabled}`}
                    download={`${card?.title.replace(' ', '_')}.pdf`}
                    href={`data:application/octet-stream;base64,${PDF}`}>
                    <div>
                      <IconDownload />
                    </div>
                    <h3>Descargar</h3>
                  </a>
                  <a href={`mailto:`} style={{ display: 'none' }}>
                    <div>
                      <EmailSVG />
                    </div>
                    <h3>Enviar</h3>
                  </a>
                  <div style={{ display: 'none' }}>
                    <ComponentToPrint refPrint={refPrint} analPerson={analPerson} PDF={PDF} />
                  </div>
                </>)
              }
            </div>
          </div>
          {!!hideButtonsScroll && listNews !== null && !!listNews.length && (
            <div className={style.scrolling}>
              <div
                onClick={() => {
                  scrollHandlerTop();
                }}
                className={`${scrollTop && style.dimming}`}>
                <SrcollUp />
              </div>
              <div
                onClick={() => {
                  scrollHandlerBottom();
                }}
                className={`${scrollBottom && style.dimming}`}>
                <SrcollDown />
              </div>
            </div>
          )}

          {card?.clinica !== undefined && (
            <div className={style.house_desktop}>
              <div style={{ margin: '3px 0px 0px 0px' }}>
                <HouseSVG />
              </div>
              <p style={{ margin: 0 }}>{card?.clinica}</p>
            </div>
          )}

          <div className={style.house_desktop}>
            <div style={{ margin: '-12px 0px 0px 0px', fontSize: '12px', fontWeight: 'bold' }}>
              &#128198;
            </div>
            <p style={{ margin: '0px 0px 10px 0px', paddingLeft: '9px' }}>{card?.data}</p>
          </div>

          <div
            className={`${style.listNews}`}
            style={{ overflow: !hideButtonsScroll && 'hidden' }}
            onScroll={scroll}
            ref={heightWrapperUl}>
            <ul ref={heightUl}>
              {listNews !== null && listNews.length > 0 &&
                listNews.map((labNews) => {
                  return (
                    <li
                      key={labNews.id}
                      className={style.one_new}
                      style={{ backgroundColor: checkForColor(labNews.flag) }}>
                      <h3>{labNews.title}</h3>
                      <div className={style.moli}>
                        <div className={style.min}>
                          {getLabNewsMin(labNews)}
                        </div>
                        <div className={style.max}>
                          <span>{`${labNews.max ?? ''} `}</span>
                        </div>
                      </div>
                    </li>
                  );
                })}
            </ul>
          </div>
          <div className={style.button}>
            <Button
              variant="contained"
              color="primary"
              disabled={!hasPdf || !PDF.length}
              startIcon={<ImageDownlaodSVG />}
              onClick={() => getPDFHandlerMob()}>
              <span>{!hasPdf ? 'Imagen No Disponible' : !PDF.length ? 'Cargando Imagen ...' : 'Ver Imagen'}</span>
            </Button>
          </div>
          <div className={style.gradient} />
        </>
      )}
    </div>
  );
};

export default DrawerCard;
