import React, { useEffect, useState } from 'react';

import { AppInsightsErrorBoundary } from "@microsoft/applicationinsights-react-js";
import ConfirmPart from '../../components/ConfirmPart';
import EmailPart from '../../components/EmailPart';
import ReactGA from 'react-ga4';
import logo from '../../assets/img/logo.svg';
import { reactPlugin } from "../../core/services/appInsights";
import style from './style.module.scss';
import { useLocation } from 'react-router-dom';

const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const formatPhoneNumber = (phoneNumberString) => {
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  if (cleaned.length < 10) {
    return null;
  }

  return `${cleaned.substring(0, 3)}-${cleaned.substring(3, 6)}-${cleaned.substring(6, 10)}`
}

const getPhoneExtension = (phoneNumberString) => {
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  if (cleaned.length <= 10) {
    return null;
  }

  return cleaned.substring(10);
}

const RequestPage = () => {
  const [isRequested, setIsRequested] = useState(false);
  const query = useQuery();
  const [email, setEmail] = useState(query.get('email') || '');
  const orgName = query.get('org-name') || '';
  const orgTel = formatPhoneNumber(query.get('org-tel')) || '';
  const orgTelExt = getPhoneExtension(query.get('org-tel')) || '';

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/request", title: "Request" });
  }, []);

  const errorMessage = <h1>Un error ha ocurido. Favor trate de nuevo o envie un correo electronico a servicio@saludmedica.com para asistencia. Gracias.</h1>;

  return (
    <AppInsightsErrorBoundary onError={() => errorMessage} appInsights={reactPlugin}>
      <div className={style['expired-page']}>
        <div className={style.wrapper}>
          {!isRequested ? (
            <EmailPart setIsRequested={setIsRequested} setEmail={setEmail} email={email} orgName={orgName} orgTel={orgTel} orgTelExt={orgTelExt} />
          ) : (
            <ConfirmPart setIsRequested={setIsRequested} email={email} orgName={orgName} orgTel={orgTel} orgTelExt={orgTelExt} />
          )}
        </div>
        <div className={style.footer}>
          <div className={style.copyright}>&#169; Copyright 2011-present. All Rights Reserved.</div>
          <img src={logo} alt="Salud Medica Inc." />
          <div>Powered by Salud Medica Inc.</div>
        </div>
      </div>
    </AppInsightsErrorBoundary>
  );
};

export default RequestPage;
