import { pdfjs } from 'react-pdf';

let workerUrl;
export function initialize() {
    if (workerUrl) {
        pdfjs.GlobalWorkerOptions.workerSrc = workerUrl;
        return;
    }

    fetch(`//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`)
        .then(fetchResponse => fetchResponse.text())
        .then(code => {
            const codeBlob = new Blob([code], { type: "text/javascript" });
            workerUrl = URL.createObjectURL(codeBlob);
            pdfjs.GlobalWorkerOptions.workerSrc = workerUrl;
        })
        .catch(error => {
            console.error("Error initializing PDF worker:", error);
        });
}