import React from 'react';
import style from './style.module.scss';

const News = ({ labNews }) => {
  const checkForColor = () => {
    if (!labNews.flag) return 'white';
    if (labNews.flag) return '#FFDFAF';
  };

  const getLabNewsMin = (labNews) => {
    if (!labNews.flagValue) {
      return <div>{`${labNews.min ?? ''}`}</div>;
    }

    switch (labNews.flagValue) {
      case 'L':
        return <div>{`${labNews.min ?? ''}`}  &#8595;</div>;
      case 'LL':
        return <div>{`${labNews.min ?? ''}`}  &#8650;</div>;
      case 'H':
        return <div>{`${labNews.min ?? ''}`}  &#8593;</div>;
      case 'HH':
        return <div>{`${labNews.min ?? ''}`}  &#8648;</div>;
      default:
        return <div>{`${labNews.min ?? ''}`} &#10003;</div>;
    }
  }

  return (
    <>
      {labNews.flag && (
        <li className={style.one_new} style={{ backgroundColor: checkForColor() }}>
          <h3>{labNews.title}</h3>
          <div className={style.moli}>
            <div className={style.min}>
              {getLabNewsMin(labNews)}
            </div>
            <div className={style.max}>
              <div>{`${labNews.max ?? ''}`}</div>
            </div>
          </div>
        </li>
      )}
    </>
  );
};

export default News;
