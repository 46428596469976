import { Document, Page, pdfjs } from 'react-pdf';
import React, { useState } from 'react';

import style from './style.module.scss';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const ComponentToPrint = ({ refPrint, PDF }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(() => numPages);
    setPageNumber(() => 1);
  };
  return (
    <div className={style.print} ref={refPrint}>
      {numPages !== null && numPages <= 1 ? (
        <div className={`container_pdf `}>
          <Document
            file={`data:application/pdf;base64,${PDF[0]}`}
            // options={{ workerSrc: '/pdf.worker.js' }}
            onLoadSuccess={onDocumentLoadSuccess}
            renderMode="canvas">
            <Page pageNumber={pageNumber} />
          </Document>
        </div>
      ) : (
        <Document
          file={`data:application/pdf;base64,${PDF[0]}`}
          // options={{ workerSrc: '/pdf.worker.js' }}
          onLoadSuccess={onDocumentLoadSuccess}>
          {Array.from(new Array(numPages), (el, index) => (
            <Page key={`page_${index + 1}`} pageNumber={index + 1} />
          ))}
        </Document>
      )}
    </div>
  );
};

export default ComponentToPrint;
