import React, { useEffect } from 'react';

import ReactGA from 'react-ga4';
import { jwtDecode } from 'jwt-decode';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const TokenDecode = () => {
  const navigate = useNavigate();
  const { hash } = useLocation();
  const token = hash.split('=')[hash.split('=').length - 1];

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/", title: "Token Decode" });
    if (token === undefined || token === '' || token === null) {
      return navigate('/request');
    } else {
      localStorage.setItem('token', token);
      try {
        const decode = jwtDecode(token);
        const dateToExpired = new Date().getTime() > decode.exp * 1000;
        localStorage.setItem('name', `${decode.given_name} ${decode.family_name}`);

        if (token.split('.').length !== 3) {
          localStorage.removeItem('token');
          navigate('/request');
        } else if (dateToExpired) {
          localStorage.setItem('name', `${decode.given_name} ${decode.family_name}`);
          navigate('/expired');
        } else {
          navigate('/results');
        }
      } catch {
        navigate('/request');
      }
    }
  }, [navigate, token]);

  return <div />;
};
export default TokenDecode;
