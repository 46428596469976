import './style.scss';
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';

import { Document, Page } from 'react-pdf';
import React, { useEffect, useState } from 'react';

import { IconDownload } from '../icons';
import { initialize } from '../../core/services/pdf-loader';
import useAnalyticsEventTracker from '../../core/hooks/analyticsEventTracker';
import { useCards } from '../cardsContext';

// pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const PdfComponent = ({ PDF, title }) => {
  initialize()
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  const { setPdfVisible, setDrawerPdfDesktop, setPdfDownload } = useCards();

  const gaEventTracker = useAnalyticsEventTracker('PDF');

  useEffect(() => {
    setPdfDownload({
      document: PDF,
      title: title,
    });
  }, [PDF, setPdfDownload, title]);

  const pdfTitle = title || 'PRUEBA';

  return (
    <div className="container_pdf">
      <div className="container_pdf-download">
        <a download={`${pdfTitle.replace(' ', '_')}.pdf`} href={`data:application/octet-stream;base64,${PDF[0]}`}>
          <IconDownload />
          <span>Descargar</span>
        </a>
      </div>
      {window.document.body.clientWidth >= 728 && (
        <div
          className="closeDesktop"
          onClick={() => {
            gaEventTracker('Close', 'Viewer');
            setPdfVisible(() => false);
            setDrawerPdfDesktop(() => false);
            setPdfDownload(null);
          }}>
          Cerrar
        </div>
      )}

      {numPages !== null && numPages <= 1 ? (
        <div className={`container_pdf `}>
          <Document
            file={`data:application/pdf;base64,${PDF[0]}`}
            // options={{ workerSrc: '/pdf.worker.js' }}
            onLoadSuccess={onDocumentLoadSuccess}>
            <Page pageNumber={pageNumber} />
          </Document>
        </div>
      ) : (
        <Document
          file={`data:application/pdf;base64,${PDF[0]}`}
          // options={{ workerSrc: '/pdf.worker.js' }}
          onLoadSuccess={onDocumentLoadSuccess}>
          {Array.from(new Array(numPages), (el, index) => (
            <Page key={`page_${index + 1}`} pageNumber={index + 1} />
          ))}
        </Document>
      )}
    </div>
  );
};
export default PdfComponent;
