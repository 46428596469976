const API_URL = process.env.REACT_APP_API_URL;
const API_KEY = process.env.REACT_APP_API_KEY;
const API_ENV = process.env.REACT_APP_API_ENV;
const API_CLIENT = process.env.REACT_APP_API_CLIENT;

export const getCards = (navigate) => {
  return new Promise((resolve, reject) => {
    fetch(`${API_URL}/patients/v1/tests/`, {
      method: 'GET',
      headers: {
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/json',
        'smx-api-key': API_KEY,
        'smx-api-env': API_ENV,
        'smx-api-client': API_CLIENT,
        Accept: 'application/json',
        Authorization: `bearer ${localStorage.getItem('token')}`,
      },
    })
      .then((resp) => {
        if (resp.ok) {
          return resp.json();
        } else if (resp.status === 404) {
          navigate('/empty');
        } else {
          return resp.json().then((data) => {
            if (resp.status === 401 || resp.status === 403 || resp.status === 400) {
              navigate('/expired');
            }
            throw new Error(data.message);
          });
        }
      })
      .then((data) => {
        resolve(data.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const viewCard = (id, navigate) => {
  return new Promise((resolve, reject) => {
    fetch(`${API_URL}/patients/v1/tests/${id}/viewed`, {
      method: 'POST',
      headers: {
        'Cache-Control': 'no-cache',
        'smx-api-key': API_KEY,
        'smx-api-env': API_ENV,
        'smx-api-client': API_CLIENT,
        Authorization: `bearer ${localStorage.getItem('token')}`,
      },
    })
      .then((resp) => {
        if (resp.ok) {
          return resp;
        } else {
          return resp.json().then((data) => {
            if (resp.status === 401 || resp.status === 403 || resp.status === 400) {
              navigate('/expired');
            }
            throw new Error(data.message);
          });
        }
      })
      .then((data) => {
        resolve(data.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getPDF = (id, navigate) => {
  return new Promise((resolve, reject) => {
    fetch(`${API_URL}/patients/v1/tests/${id}`, {
      method: 'GET',
      headers: {
        'Cache-Control': 'no-cache',
        'smx-api-key': API_KEY,
        'smx-api-env': API_ENV,
        'smx-api-client': API_CLIENT,
        Authorization: `bearer ${localStorage.getItem('token')}`,
      },
    })
      .then((resp) => {
        if (resp.ok) {
          return resp.json();
        } else {
          return resp.json().then((data) => {
            if (resp.status === 401 || resp.status === 403 || resp.status === 400) {
              navigate('/expired');
            }
            throw new Error(data.message);
          });
        }
      })
      .then((data) => {
        resolve(data.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
