import { BackSVG, FilterSVG, IconDownload } from '../../icons';
import React, { useEffect, useState } from 'react';

import Button from '@material-ui/core/Button';
import { CancelFilterSVG } from '../../icons';
import Drawer from '@material-ui/core/Drawer';
import FilterDrawer from './FilterDrawer';
import logo from '../../../assets/img/logo.svg';
import style from './style.module.scss';
import useAnalyticsEventTracker from '../../../core/hooks/analyticsEventTracker';
import { useCards } from '../../cardsContext';

const Header = () => {
  const [open, setOpen] = useState(false);
  const { pdfMob, setPdfMob, setPdfVisible, setBlur, draweropen, setDrawerOpen, pdfDownload, setPdfDownload } = useCards();

  const gaEventTrackerFilter = useAnalyticsEventTracker('Filter');
  const gaEventTrackerViewer = useAnalyticsEventTracker('Results');
  const handleToggle = () => {
    gaEventTrackerFilter('Open', 'Options');
    setOpen(() => true);
    if (window.document.body.clientWidth >= 728) {
      setBlur(() => true);
    }
  };
  const handleClose = () => {
    gaEventTrackerFilter('Close', 'Options');
    setOpen(() => false);
    if (window.document.body.clientWidth >= 728) {
      setBlur(() => false);
    }
  };

  useEffect(() => {
    if (open) document.body.style.overflow = 'hidden';
    if (!open) document.body.style.overflow = 'unset';
  }, [open]);

  const closeHandler = () => {
    gaEventTrackerViewer('Close', 'Viewer');
    setPdfVisible(() => false);
    setPdfMob(() => false);
    setPdfDownload(null);
  };

  const pdfTitle = pdfDownload?.title || 'PRUEBA';

  return (
    <div className={`${style.header} ${window.document.body.clientWidth > 727 && style.desktop} `} id="header">
      <div className={style.wrapper__}>
        {!pdfDownload && (
          <div className={style.wrapperFlex}>
            <img src={logo} alt="logo" className={style.img} />
            <div className={style.results}>Resultados</div>
          </div>
        )}
        {pdfDownload && (
          <div className={style.download}>
            <a
              download={`${pdfTitle.replace(' ', '_')}.pdf`}
              href={`data:application/octet-stream;base64,${pdfDownload.document[0]}`}>
              <IconDownload />
              <span>Descargar</span>
            </a>
          </div>
        )}

        {window.document.body.clientWidth <= 727 && pdfMob && (
          <div className={style.mobClose} onClick={() => closeHandler()}>
            Cerrar
          </div>
        )}

        {window.document.body.clientWidth <= 727 && draweropen && !pdfMob && (
          <span
            className={style.block_back}
            onClick={() => {
              setDrawerOpen(() => false);
              setBlur(() => false);
            }}>
            <div>
              <BackSVG />
            </div>
            <h4>REGRESAR</h4>
          </span>
        )}

        <div className={style.filter} id="filter_toggle">
          {!open ? (
            <Button onClick={handleToggle}>
              <FilterSVG />
              <div className={style.filter_text}>Filtrar</div>
            </Button>
          ) : (
            <Button onClick={handleClose}>
              <CancelFilterSVG />
              <div className={style.filter_text}>Filtrar</div>
            </Button>
          )}
        </div>
        <Drawer
          open={open}
          className={`drawerFilter ${window.document.body.clientWidth > 727 ? 'desktop' : 'mobile'}`}
          anchor="right"
          onClose={() => {
            setBlur(() => false);
            setOpen(() => false);
          }}>
          <FilterDrawer setBlur={setBlur} setOpen={setOpen} />
        </Drawer>
      </div>
    </div>
  );
};

export default Header;
