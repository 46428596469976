import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { useAppInsightsContext, useTrackEvent } from "@microsoft/applicationinsights-react-js";

import Button from '@material-ui/core/Button';
import { IconBack } from '../icons';
import InputAdornment from '@material-ui/core/InputAdornment';
import { MailSVG } from '../icons';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { postLogin } from '../../core/services/login';
import style from './style.module.scss';
import useAnalyticsEventTracker from '../../core/hooks/analyticsEventTracker';

const EmailPart = ({ setIsRequested, setEmail, email, orgName, orgTel, orgTelExt }) => {
  const appInsights = useAppInsightsContext();
  const trackSecureLinkRequest = useTrackEvent(appInsights, "SecureLinkRequest", {
    patientEmail: email,
    orgName: orgName
  });

  const hasGmailEmailAddress = email && email.includes("gmail");

  const gaEventTracker = useAnalyticsEventTracker('Access');

  const orgNameDescription = orgName ? orgName : 'laboratorio';
  const useStyles = makeStyles((theme) => ({
    root: {},
    clr: {
      background: '#F9FBFF',
      maxWidth: '450px',
      [theme.breakpoints.up('sm')]: {
        maxWidth: '523px !important',
        width: '100%',
      },
    },
  }));

  const classes = useStyles();

  const handleSubmit = () => {
    trackSecureLinkRequest();
    gaEventTracker('Click', 'Send access link');
    setIsRequested(() => true);
    const body = {
      emailAddress: email
    };
    postLogin(body).catch((err) => appInsights.trackException({ exception: err, properties: body }));
  };

  return (
    <div className={style.email}>
      <div className={style.text}>
        {orgName && orgTel ? (
          <div>
            ¡Saludos!&nbsp;
            {email ? (
              <div>
                <strong>Verifique que la dirección de correo electrónico presentada esté correcta.</strong> Si no es así, puede llamar al {orgName} al
                <a href={`tel:${orgTel}${orgTelExt ? orgTelExt : ''}`} style={{ color: '#3685d4', textDecoration: 'none', fontWeight: 'bold' }}> &#9742; {orgTel} </a>
                para realizar la corrección de la misma.
                <br /><br />
                Haga click en <strong>Acceder Resultados</strong> para recibir el enlace seguro en su buzón de correo electrónico y, así, poder ver sus resultados.
              </div>
            ) : (
              <div>
                Para acceder sus resultados ingrese en el siguiente campo el <strong>correo electrónico que le proporcionó al {orgName}
                  <sup style={{ verticalAlign: 'baseline', position: 'relative', top: '-0.4em' }}>
                    &#9829;
                  </sup>
                </strong> durante el registro y luego haga click a <strong>Acceder Resultados</strong> para recibir el enlace seguro en su buzón.
              </div>
            )}
          </div>
        ) : (
          <div>
            ¡Saludos! Para acceder sus resultados ingrese en el siguiente campo el <strong>correo electrónico que le proporcionó al {orgNameDescription}
              <sup style={{ verticalAlign: 'baseline', position: 'relative', top: '-0.4em' }}>
                &#9829;
              </sup>
            </strong> durante el registro y luego haga click a <strong>Acceder Resultados</strong> para recibir el enlace seguro en su buzón.
          </div>
        )}
      </div>
      <ValidatorForm onSubmit={handleSubmit} className={classes.root} autoComplete="off">
        <TextValidator
          validators={['required', 'isEmail']}
          errorMessages={[
            'El correo electrónico ingresado no es válido',
            'El correo electrónico ingresado no es válido',
          ]}
          value={email}
          type="email"
          name="email"
          onChange={(e) => {
            setEmail(() => e.target.value);
          }}
          className={classes.clr}
          variant="outlined"
          placeholder="Ingrese su correo electrónico"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <MailSVG />
              </InputAdornment>
            ),
          }}
        />
        {hasGmailEmailAddress && (
          <div>
            <div className={style.gmail}>
              <div className={style.attention}>¡ATENCION USUARIOS DE GMAIL!</div>
              Por favor agregue <span className={style.address}>servicio@saludmedica.com</span> a su lista de contactos en Gmail.
              Si no recibe el correo electrónico en unos minutos, <span className={style.cta}>revise su buzón de SPAM y muévalo a su Inbox para poder acceder el portal</span>.
            </div>
          </div>
        )}
        <div className={style.button}>
          <Button variant="contained" color="primary" starticon={<IconBack />} type="submit">
            Acceder Resultados
          </Button>
        </div>
      </ValidatorForm>

      <div className={style.help}>
        {!email && (
          <div>
            <sup>&#9829;</sup> Favor de llamar {orgName ? ' a ' : ' al '} {orgNameDescription} {orgTel ? ' al ' : ''}
            {orgTel ? <strong><a href={`tel:${orgTel}${orgTelExt ? `,${orgTelExt}` : ''}`} style={{ color: '#3174e4', textDecoration: 'none' }}> &#9742; {orgTel}{orgTelExt ? ` ext. ${orgTelExt}` : ''} </a></strong> : ''}
            para proporcionar su correo electrónico si aún no lo ha hecho.
          </div>
        )}
      </div>
    </div>
  );
};

export default EmailPart;
