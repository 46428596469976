import { HeardGreenSVG, HeardGreySVG, HeardOrangeSVG, HouseSVG } from '../icons';
import React, { useState } from 'react';

import { AllGoodResSVG } from '../icons';
import Button from '@material-ui/core/Button';
import News from '../../components/News';
import { getNumberOfDaysFromToday } from '../../core/services/utils';
import style from './style.module.scss';
import useAnalyticsEventTracker from '../../core/hooks/analyticsEventTracker';
import { useCards } from '../cardsContext';

const Cards = ({ setAnalPerson, item, setDrawerOpen/*, setConfig, config, setConfigFiltered*/ }) => {
  const [expand, setExpand] = useState(false);
  const { setBlur } = useCards();

  const gaEventTracker = useAnalyticsEventTracker('Results');

  const checkForColor = (flag) => {
    if (item.is_new || item.is_expired || item.is_blocked) return '#A2ACBE';
    if (!flag) return '#1EBF49';
    if (flag) return '#EB9718';
  };
  const checkForHeard = (flag) => {
    if (item.is_new || item.is_expired || item.is_blocked) return <HeardGreySVG />;
    if (!flag) return <HeardGreenSVG />;
    if (flag) return <HeardOrangeSVG />;
  };

  const checkForFn = () => {
    /*    if (!item.looked)
          viewCard(item.id, history).then(() => {
            const newInfo = config.map((inf) => {
              if (inf.id === item.id) {
                inf.looked = true;
              }
              return inf;
            });
            if (configFiltered !== null) {
              setConfigFiltered(() => newInfo);
            }
            if (configFiltered === null) {
              setConfig(() => newInfo);
            } else {
              for (let inf of config) {
                if (inf.id === item.id) {
                  inf.looked = true;
                }
              }
            }
          });*/
    if (window.document.body.clientWidth >= 728) {
      gaEventTracker('Open', 'Results drawer');
      setAnalPerson(() => item.id);
      setDrawerOpen(() => true);
      setBlur(() => true);
      return;
    }
    setAnalPerson(() => item.id);
    if (!item.flag) {
      gaEventTracker('Open', 'Results drawer');
      setDrawerOpen(true);
    }
    if (item.flag) {
      gaEventTracker('Evaluate', 'Out of range results');
      setExpand(true);
    }
  };

  const actionsHandler = () => {
    setAnalPerson(() => item.id);
    setDrawerOpen(true);
    setExpand(() => false);
  };

  const getDescriptionText = (item) => {
    switch (item.status) {
      case 'Canceled':
        return 'CANCELADO';
      case 'Created':
        const days = getNumberOfDaysFromToday(item.data);
        return days <= 15 ? 'PENDIENTE PROCESAR' : 'NO PROCESADO';
      case 'Processing':
        return 'PROCESANDO';
      case 'MissingSample':
        return 'FALTA MUESTRA';
      case 'Expired':
        return 'CADUCADA';
      case 'Blocked':
        return 'FAVOR LLAMAR LABORATORIO';
      default:
        return !item.flag ? 'ANALITOS EN RANGO' : 'ANALITOS FUERA DE RANGO';
    }
  };

  return (
    <div className={`${style.card} ${expand && style.extend}`}>
      <div className={style.line} style={{ background: checkForColor(item.flag) }} />
      <div className={style.main_info}>
        <div className={style.block}>
          <div className={style.description} style={{ color: checkForColor(item.flag) }}>
            <div className="heart">{checkForHeard(item.flag)}</div>
            <span className={style.desc_text}>
              {getDescriptionText(item)}
            </span>
          </div>
          <div className={style.data}>{item.data}</div>
        </div>

        {item.is_blocked && <div className={style.text}>{item.clinica}</div>}
        <div className={item.is_new ? style.title_nowrap : style.title}>{item.title}</div>
        <div className={style.text}>{`${item.first_name} ${item.last_name}, ${item.age}`}</div>
        {expand && (
          <div className={style.news}>
            <div className={style.lab_title}>
              <div>
                <HouseSVG />
              </div>
              <h2>{item.clinica}</h2>
            </div>
            <ul className={style.listNews}>
              {item.news.map((newN) => {
                return <News labNews={newN} key={`${newN.id}`} />;
              })}
            </ul>
            <div className={style.all_good}>
              <div>
                <AllGoodResSVG />
              </div>
              <p>El resto de los analitos están en rango</p>
            </div>
          </div>
        )}
        {expand ? (
          <>
            <div className={style.Ebtn}>
              <Button variant="contained" color="primary" className={style.Ebtn} onClick={() => {
                gaEventTracker('Close', 'Results drawer');
                setExpand(false);
              }}>
                Cerrar
              </Button>
            </div>
            <div className={style.button}>
              <Button variant="contained" color="primary" onClick={actionsHandler}>
                Ver Todos
              </Button>
            </div>
          </>
        ) : (
          <div className={style.button}>
            {!item.is_new && !item.is_expired && !item.is_blocked && (
              <Button variant="contained" color="primary" /*startIcon={<EyeSVG />}*/ onClick={checkForFn}>
                {item.flag ? 'Evaluar' : 'Ver Valores'}
              </Button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Cards;
