export const getNumberOfDaysFromToday = (end) => {
  const today = new Date();
  const date = new Date(end);

  // One day in milliseconds
  const oneDay = 1000 * 60 * 60 * 24;

  // Calculating the time difference between two dates
  const diffInTime = today.getTime() - date.getTime();
  return Math.round(diffInTime / oneDay);
}