import React from 'react';
import Button from '@material-ui/core/Button';
import { postLogin } from '../../core/services/login';

import style from './style.module.scss';

const ExpiredPart = ({ setIsRequested, email }) => {
  return (
    <div className={style.request}>
      <h1>
        ¡Saludos!
      </h1>
      <div className={style.text}>
        Su enlace seguro ha caducado. Haga click en <strong>Reenviar Enlace Seguro</strong> para reenviarle uno nuevo.
      </div>
      <br/>
      <div className={style.button}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setIsRequested(true);
            const body = {
              emailAddress: email
            };
            postLogin(body).catch((error) => {});
          }}>
          Reenviar Enlace Seguro
        </Button>
      </div>
    </div>
  );
};

export default ExpiredPart;
