const API_URL = process.env.REACT_APP_API_URL;
const API_KEY = process.env.REACT_APP_API_KEY;
const API_ENV = process.env.REACT_APP_API_ENV;
const API_CLIENT = process.env.REACT_APP_API_CLIENT;

export const postLogin = (body) => {
  return new Promise((resolve, reject) => {
    fetch(`${API_URL}/auth/v1/login`, {
      method: 'POST',
      headers: {
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/json',
        'smx-api-key': API_KEY,
        'smx-api-env': API_ENV,
        'smx-api-client': API_CLIENT,
      },
      body: JSON.stringify(body),
    })
      .then((resp) => {
        if (resp.ok) {
          return resp.json();
        } else {
          return resp.json().then((json) => {
            throw new Error(json.message);
          });
        }
      })
      .then((data) => {
        resolve(data.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
