import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import { IconBack} from '../icons';
import confirm from '../../assets/img/confirm-header.png';
import style from './style.module.scss';

const ConfirmPart = ({ email, setIsRequested, orgName, orgTel, orgTelExt }) => {
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const orgNameDescription = orgName ? orgName : 'laboratorio';

  useEffect(() => {
    setTimeout(() => setButtonDisabled(() => false), 5000);
  })

  return (
    <div className={style.confirm}>
      <div className={style.header}>
        <img src={confirm} alt="" />
      </div>
      <div className={style.wrapper}>
        <h1>Correo electrónico enviado</h1>
        <div className={style.text}>
          Un correo electrónico con el enlace seguro, válido por 24 horas, ha sido enviado a
          <br/><br/>
          <div style={{
            letterSpacing: '1px',
            fontSize: '1.5em',
            overflowWrap: 'break-word'
          }}>
            <a href={`mailto:${email}`}>{email}</a>
          </div>
          <br/>
          <small>
            Verifique que el correo electrónico que aparece en el recuadro esté correcto. Si no es correcto, favor de llamar
            {orgName ? ' a ' : ' al '} {orgNameDescription} {orgTel ? ' al ' : ''} {orgTel ? <strong><a href={`tel:${orgTel}${orgTelExt ? `,${orgTelExt}` : ''}`} style={{color: '#3685d4', textDecoration: 'none'}}> &#9742; {orgTel}{orgTelExt ? ` ext. ${orgTelExt}` : ''} </a></strong> : ''}
            para hacer la corrección. Si está correcto y no ha recibido el enlace en 5 minutos, favor verifique su buzón de <strong>spam</strong> o <strong>junk</strong>.
          </small>
        </div>
        {!buttonDisabled && (
          <div className={style.button}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<IconBack />}
              onClick={() => setIsRequested(() => false)}>
              Regresar
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ConfirmPart;
