import './global.scss';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';

import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import EmptyPage from './pages/EmptyPage';
import ExpiredPage from './pages/ExpiredPage';
import { GetInfoCardsProvider } from './components/cardsContext';
import React from 'react';
import ReactGA from 'react-ga4';
import RequestPage from './pages/RequestPage';
import Results from './pages/Results';
import { StylesProvider } from '@material-ui/core/styles';
import TokenDecode from './pages/TokenDecode';
import { createRoot } from 'react-dom/client';
import { reactPlugin } from "./core/services/appInsights";
import reportWebVitals from './reportWebVitals';

ReactGA.initialize("G-9Y2371PHGV", { gtagUrl: 'https://www.googletagmanager.com/gtag/js?id=G-9Y2371PHGV', testMode: false });

const App = () => (
  <AppInsightsContext.Provider value={reactPlugin}>
    <GetInfoCardsProvider>
      <StylesProvider injectFirst>
        <Router>
          <Routes>
            <Route exact path="/" element={<TokenDecode />} />
            <Route path="request" element={<RequestPage />} />
            <Route path="expired" element={<ExpiredPage />} />
            <Route path="empty" element={<EmptyPage />} />
            <Route path="results" element={<Results />} />
          </Routes>
        </Router>
      </StylesProvider>
    </GetInfoCardsProvider>
  </AppInsightsContext.Provider>
);

const root = createRoot(document.getElementById('root'));
root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
